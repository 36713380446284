import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import axios from "axios";
import * as Icon from "react-feather";
import { IntlContext } from "../../../utility/context/Internationalization";
import { connect } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import { logoutUser } from "../../../redux/actions/auth/loginActions";
import { switchLocationCountry } from "../../../redux/actions/country";
import { emptyReduxData } from "../../../redux/actions/new_bookings";
import { getData as getServiceData } from "../../../redux/actions/service_class";
import {
  getData as getNewBookings,
  locationUpdateLoading,
} from "../../../redux/actions/new_bookings";
import { getData as getAssignBookings } from "../../../redux/actions/assign_booking";
import { getData as getScheduleBookings } from "../../../redux/actions/completed_bookings";
import { getData as getCompletedBookings } from "../../../redux/actions/completed_bookings";
import { getData as getCancelledBookings } from "../../../redux/actions/cancelled_booking";
import { getData as getPartner } from "../../../redux/actions/partnerCompanyPatner";
import { getData as getNewRegister } from "../../../redux/actions/partnerNewRegistered";
import { getData as getIndividualRegister } from "../../../redux/actions/partnerIndividualDriver";
import { getData as getPartnerRegister } from "../../../redux/actions/partnerCompanyPatner";
import { getData as get_service_classes_price } from "../../../redux/actions/pricing";
import {
  getCards,
  getRevenue,
  getBooking,
} from "../../../redux/actions/dashboard";
import moment from "moment";
import { getData as getSubAdminData } from "../../../redux/actions/sub_admin";
// import { getData as getHourlyPrice} from '../../../redux/actions/pricing'
import ic_admin_user from "../../../assets/img/logo/ic_admin_user.png";
import { locationCountry } from "../../../redux/actions/country";
import LogoutModal from "../modal/LogoutModal";

class NavbarUser extends React.PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (props.user != null) {
      return {
        first_name: props.user.name,
        user_type: props.user.user_type,
        country: props.country,
        userRole: props?.userRole?.userRole,
      };
    }
    return null;
  }

  state = {
    first_name: "",
    last_name: "",
    user_type: "",
    navbarSearch: false,
    suggestions: [],
    langDropdown: false,
    countryCode: "",
    countryName: "",
    country: [],
    modal: false,
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      countryCode: JSON.parse(localStorage.getItem("location"))?.country_code,
      countryName: JSON.parse(localStorage.getItem("location"))?.name,
    });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };
  handleLangDropdown = () => {
    this.setState({ langDropdown: !this.state.langDropdown });
  };
  handleCountryChange = async (
    id,
    country_code,
    country_name,
    currency,
    currency_symbol
  ) => {
    axios.defaults.headers.common["location_id"] = id;
    localStorage.setItem(
      "location",
      JSON.stringify({
        name: country_name,
        _id: id,
        country_code: country_code,
        currency: currency,
        currency_symbol: currency_symbol,
      })
    );
    this.setState({
      ...this.state,
      countryCode: country_code,
      countryName: country_name,
    });
    console.log(window.location.pathname);
    this.props.switchLocationCountry(id);
    if (window.location.pathname == "/offers") {
      // await this.props.getData();
    }

    if (window.location.pathname === "/sub-admin") {
      // this.props.locationUpdateLoading(true);
      // await this.props.getSubAdminData();
      // this.props.locationUpdateLoading(false);
    }
    if (window.location.pathname == "/service-class") {
      await this.props.getServiceData();
    }
    if (window.location.pathname == "/bookings") {
      this.props.emptyReduxData();
      let params = {
        page: 1,
        limit: 20,
        offer_type: "New",
      };
      const obj = {
        first: "New",
        second: "Assign",
        third: "schedule",
        fourth: "completed",
        fifth: "cancelled",
      };

      if (this.props.bookingActiveTab) {
        params.offer_type = obj[this.props.bookingActiveTab];
      }
      this.props.locationUpdateLoading(true);
      if (obj[this.props.bookingActiveTab] === "New")
        await this.props.getNewBookings(params);
      else if (obj[this.props.bookingActiveTab] === "Assign")
        await this.props.getAssignBookings(params);
      else if (obj[this.props.bookingActiveTab] === "schedule")
        await this.props.getScheduleBookings(params);
      else if (obj[this.props.bookingActiveTab] === "completed")
        await this.props.getCompletedBookings(params);
      else if (obj[this.props.bookingActiveTab] === "cancelled")
        await this.props.getCancelledBookings(params);

      this.props.locationUpdateLoading(false);
    }
    if (window.location.pathname == "/partner") {
      this.props.locationUpdateLoading(true);
      if (this.props.activeTab === "first")
        await this.props.getIndividualRegister();
      else if (this.props.activeTab === "second")
        await this.props.getPartnerRegister();
      else if (this.props.activeTab === "third")
        await this.props.getNewRegister();

      this.props.locationUpdateLoading(false);
      // this.props.getPartner();
    }
    if (window.location.pathname == "/hourly-rates") {
      this.props.locationUpdateLoading(true);
      await this.props.get_service_classes_price();
      this.props.locationUpdateLoading(false);
    }
    if (window.location.pathname == "/normal-rates") {
      this.props.locationUpdateLoading(true);
      await this.props.get_service_classes_price();
      this.props.locationUpdateLoading(false);
    }
    if (window.location.pathname == "/") {
      this.props.locationUpdateLoading(true);
      await this.props.getCards();
      await this.props.getRevenue({
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment(new Date()).format("YYYY-MM-DD"),
      });
      await this.props.getBooking({
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment(new Date()).format("YYYY-MM-DD"),
      });
      this.props.locationUpdateLoading(false);
    }
  };

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right align-items-center">
        <li>
          <div className="d-flex align-items-center">
            <div className="user_icon">
              <img
                src={ic_admin_user}
                alt="..."
                width={"18px"}
                height={"18px"}
              />
            </div>
            <div className="user_name">
              {this.state.first_name} {this.state.last_name}
            </div>
            <div
              style={{
                color: "#A3A3A3",
                marginLeft: "6px",
                textTransform: "capitalize",
              }}
              className="mr-2"
            >
              {localStorage.getItem("User")
                ? JSON.parse(localStorage.getItem("User")).role.replace(
                    "_",
                    " "
                  )
                : ""}
              {/* {this.state.userRole} */}
            </div>
          </div>
        </li>
        <li>
          <div
            className="logout-hover mr-2"
            style={{ color: "#fff", cursor: "pointer" }}
            onClick={() => {
              this.toggle();
              // this.props.logoutUser();
            }}
          >
            Logout
          </div>
          <LogoutModal openModal={this.state.modal} toggle={this.toggle} />
        </li>
        <Dropdown
          // style={{ marginTop: "9px" }}
          tag="li"
          className="dropdown-language nav-item"
          isOpen={this.state.langDropdown}
          toggle={this.handleLangDropdown}
          data-tour="language"
        >
          <DropdownToggle
            style={{
              border: "1px solid #4E4E4E",
              padding: "10px",
              borderRadius: "10px",
            }}
            tag="a"
            className="nav-link"
          >
            <ReactCountryFlag
              className="country-flag"
              countryCode={this.state.countryCode}
              svg
            />
            <span
              style={{ marginRight: "30px" }}
              className="d-sm-inline-block d-none text-capitalize align-middle ml-50"
            >
              {this.state.countryName}
            </span>
            <img
              src={require("../../../assets/img/icons/down-arrow.svg")}
              width="20px"
              alt="down arrow icon"
            />
          </DropdownToggle>
          {this.state.country.length == 0 ? null : (
            <DropdownMenu
              right
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              {this.state.country.map((value, index) =>
                typeof value.locations !== "undefined"
                  ? value.locations.map((location) => (
                      <div>
                        <DropdownItem
                          key={index}
                          tag="a"
                          onClick={() =>
                            this.handleCountryChange(
                              location._id,
                              value.country_code,
                              location.name,
                              location?.currency,
                              location?.currency_symbol
                            )
                          }
                          className="dropdown_menu_dashboard"
                        >
                          <ReactCountryFlag
                            className="country-flag"
                            countryCode={value.country_code}
                            svg
                          />

                          <span className="ml-1">
                            {location.name + " "} ({value.country_name})
                          </span>
                        </DropdownItem>
                      </div>
                    ))
                  : null
              )}
            </DropdownMenu>
          )}
        </Dropdown>
      </ul>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userRole: state.auth.login,
    bookingActiveTab: state.add_booking.bookingActiveTab,
    user: state.auth.login.values,
    errors: state.errors,
    country: state.country.country,
    activeTab: state.partner_individual_driver.activeTab,
  };
};
export default connect(mapStateToProps, {
  logoutUser,
  locationUpdateLoading,
  getServiceData,
  switchLocationCountry,
  getNewBookings,
  getAssignBookings,
  getCancelledBookings,
  getCompletedBookings,
  getScheduleBookings,
  getPartner,
  getNewRegister,
  getIndividualRegister,
  getPartnerRegister,
  getCards,
  getRevenue,
  emptyReduxData,
  getBooking,
  getSubAdminData,
  get_service_classes_price,
  locationCountry,
})(NavbarUser);
