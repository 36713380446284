import Resturl from "../../../configs/url";
import swal from "sweetalert";
import axios from "axios";
import {
  loadingTrue,
  loadingFalse,
  loadingSidebarTrue,
  loadingSidebarFalse,
} from "../loading";
import { checkSign } from "../../../utility/sign";
import { notification } from "../../../utility/notification";
import { store } from "../../storeConfig/store";
import { changeTimezone } from "../utility";
import { useParams } from "react-router-dom";
import { history } from "../../../history";
import { reset } from "redux-form";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export const getData = (params, setInitialLoading) => {
  if (isEmpty(params)) {
    params = {
      page: 1,
      limit: 20,
    };
  }

  return async (dispatch) => {
    const signature = await checkSign(params);
    await axios
      .get(Resturl.get_service_classes_price, {
        headers: {
          signature,
        },
      })
      .then((response) => {
        if (setInitialLoading) setInitialLoading(false);
        console.log("response", response);
        dispatch({
          type: "GET_PRICING",
          data: response.data.service_classes,
          location_info: response.data.location_info,
          params,
        });
      })
      .catch((err) => {
        if (setInitialLoading) setInitialLoading(false);
      });
  };
};

export const filterData = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_DATA", value });
};

export const updateData = (obj) => {
  return (dispatch, getState) => {
    const param = {
      name: obj.name,
      color: obj.color,
      max_person: obj.max_person,
      max_luggage: obj.max_luggage,
      features: obj.features,
      color: getState().service_class.color,
      id: getState().service_class.id,
    };
    axios.put(Resturl.update_service_class, param).then((response) => {
      dispatch({ type: "UPDATE_SERVICE_CLASS", param });
      notification("Service Class", response.data.message, "success");
    });
  };
};

export const addDataOneWayPrice = (obj) => {
  return (dispatch, getState) => {
    console.log("obj123", obj);
    const price = [];
    const service_classes = [];

    obj.service_class_pricing.map((value, index) => {
      service_classes.push({
        location_service_class_id: value?.id,

        price: {
          //   ...value,
          initial_charge: value?.initial_price,
          initial_distance: value?.initial_distance,
          band_1: {
            charge: value?.band_1_price,
            distance: value?.band_1_distance,
          },
          band_2: {
            charge: value?.band_2_price,
            distance: value?.band_2_distance,
          },
          band_3: {
            charge: value?.band_3_price,
            distance: "",
          },
        },
      });
    });
    console.log("service_classes", service_classes);
    dispatch(loadingTrue());

    const param = {
      type: "ONE_WAY", //  ONE_WAY, HOURLY, AIRPORT
      service_classes: service_classes,
    };
    console.log("add-location", JSON.stringify(param));

    axios.post(Resturl.add_pricing, param).then((response) => {
      if (response?.data?.status == 1) {
        // dispatch({ type: "ADD_LOCATION", param });
        // history.push("/normal-rates");
        // store.dispatch(reset("wizard"));
        notification("Price add... ", response.data.message, "success");
      }
      dispatch(loadingFalse());
      dispatch(getData());
    });
  };
};

export const addDataHourlyPrice = (obj) => {
  return (dispatch, getState) => {
    console.log("obj123", obj);
    const price = [];
    const service_classes = [];

    obj.time_service_class.forEach((value, index) => {
      service_classes.push({
        location_service_class_id: value?.id,
        price: value?.times?.map((times_price) => {
          return {
            hour: times_price?.hour,
            km: times_price?.km,
            price: times_price?.price,
          };
        }),
      });
    });
    dispatch(loadingTrue());

    console.log("service_classes22", service_classes);
    const param = {
      type: "HOURLY", //  ONE_WAY, HOURLY, AIRPORT
      service_classes: service_classes,
    };
    console.log("add-location", JSON.stringify(param));

    axios.post(Resturl.add_pricing, param).then((response) => {
      if (response?.data?.status == 1) {
        // dispatch({ type: "ADD_LOCATION", param });
        // history.push("/normal-rates");
        // store.dispatch(reset("wizard"));
        notification("Price add... ", response.data.message, "success");
      }
      dispatch(loadingFalse());
      dispatch(getData());
    });
  };
};

export const updateDataOneWayPrice = (obj) => {
  return (dispatch, getState) => {
    console.log("obj123", obj);
    const price = [];
    const service_classes = [];

    obj.service_class_pricing.map((value, index) => {
      service_classes.push({
        location_service_class_id: value?.id,
        service_class_price_id: value?.service_class_price_id,
        price: {
          //   ...value,
          initial_charge: value?.initial_price,
          initial_distance: value?.initial_distance,
          band_1: {
            charge: value?.band_1_price,
            distance: value?.band_1_distance,
          },
          band_2: {
            charge: value?.band_2_price,
            distance: value?.band_2_distance,
          },
          band_3: {
            charge: value?.band_3_price,
            distance: "",
          },
        },
      });
    });
    console.log("service_classes", service_classes);
    dispatch(loadingTrue());

    const param = {
      type: "ONE_WAY", //  ONE_WAY, HOURLY, AIRPORT
      service_classes: service_classes,
    };
    console.log("add-location", JSON.stringify(param));

    axios.put(Resturl.add_pricing, param).then((response) => {
      if (response?.data?.status == 1) {
        // dispatch({ type: "ADD_LOCATION", param });
        // dispatch(getData());
        // history.push("/normal-rates");
        // store.dispatch(reset("wizard"));
        notification("Price ", response.data.message, "success");
      }
      console.log("response", response);
      if (response?.data?.status == 0) {
        notification("Price ", "error", "danger");
      }
      dispatch(loadingFalse());

      dispatch(getData());
    });
  };
};

export const updateDataHourlyPrice = (obj) => {
  return (dispatch, getState) => {
    console.log("obj123", obj);
    const price = [];
    const service_classes = [];

    obj.time_service_class.forEach((value, index) => {
      service_classes.push({
        location_service_class_id: value?.id,
        service_class_price_id: value?.service_class_price_id,
        price: value?.times?.map((times_price) => {
          return {
            hour: times_price?.hour,
            km: times_price?.km,
            price: times_price?.price,
          };
        }),
      });
    });

    console.log("service_classes", service_classes);
    dispatch(loadingTrue());

    const param = {
      type: "HOURLY", //  ONE_WAY, HOURLY, AIRPORT
      service_classes: service_classes,
    };
    console.log("add-location", JSON.stringify(param));

    axios.put(Resturl.add_pricing, param).then((response) => {
      if (response?.data?.status == 1) {
        // dispatch({ type: "ADD_LOCATION", param });
        // dispatch(getData());
        // history.push("/normal-rates");
        // store.dispatch(reset("wizard"));
        notification("Price", response.data.message, "success");
      }
      console.log("response", response);
      if (response?.data?.status == 0) {
        notification("Price", "error", "danger");
      }
      dispatch(loadingFalse());

      dispatch(getData());
    });
  };
};
