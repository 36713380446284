import Resturl from "../../../configs/url";

import axios from "axios";
import { loadingTrue, loadingFalse } from "../loading";
import { checkSign } from "../../../utility/sign";
import { notification } from "../../../utility/notification";
import { history } from "../../../history";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export const locationUpdateLoading = (data) => {
  return (dispatch) => {
    dispatch({
      type: "LOCATION_UPDATED_LOADING_HANDLED",
      payload: data,
    });
  };
};

export const emptyReduxData = () => {
  return (dispatch) => {
    dispatch({
      type: "GET_NEW_BOOKINGS",
      data: [],
      totalPages: 0,
      totalRecords: 0,
      all_offers_count: [],
    });
  };
};

export const getData = (params, setInitialLoading) => {
  if (isEmpty(params)) {
    params = {
      page: 1,
      limit: 20,
      offer_type: "New",
      search_data: "",
    };
  }

  return async (dispatch) => {
    const signature = await checkSign(params);
    await axios
      .post(Resturl.booking_list, params, {
        headers: {
          signature,
        },
      })
      .then((response) => {
        dispatch({
          type: "GET_NEW_BOOKINGS",
          data: response.data.offers,
          totalPages: response.data.total_page,
          totalRecords: response.data.total_offers,
          all_offers_count: response.data.all_offers_count,
          start: response.data.start,
          end: response.data.end,
          params,
        });
        if (setInitialLoading) setInitialLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (setInitialLoading) setInitialLoading(false);
      });
    // dispatch(loadingFalse());
  };
};

export const handledPayment = (id, callback) => {
  let params = {};
  return async () => {
    const signature = await checkSign(params);
    await axios
      .get("/send-payment-link/" + id)
      .then((response) => {
        console.log("🚀 ~ file: index.js:90 ~ .then ~ response:", response);
        if (callback) callback();
      })
      .catch((err) => {
        if (callback) callback();
      });
  };
};

export const filterData = (value) => {
  return (dispatch) => dispatch({ type: "FILTER_DATA", value });
};

export const deleteData = (obj) => {
  return (dispatch) => {
    axios
      .post("/api/datalist/delete-data", {
        obj,
      })
      .then((response) => {
        dispatch({ type: "DELETE_DATA", obj });
      });
  };
};

export const updateData = (obj) => {
  return (dispatch, getState) => {
    axios
      .post("/api/datalist/update-data", {
        obj,
      })
      .then((response) => {
        dispatch({ type: "UPDATE_DATA", obj });
      });
  };
};

export const addData = (obj) => {
  return (dispatch, getState) => {
    let params = getState().dataList.params;
    axios
      .post("/api/datalist/add-data", {
        obj,
      })
      .then((response) => {
        dispatch({ type: "ADD_DATA", obj });
        dispatch(getData(params));
      });
  };
};

export const cancelBooking = (id) => {
  return (dispatch, getState) => {
    axios
      .post(Resturl?.cancel_booking, {
        booking_id: id,
        reason: "test",
      })
      .then((response) => {
        console.log("response123456", response);
        if (response?.data?.status == 1) {
          notification("Asign", response.data.message, "success");
          history.push("/bookings");
        } else {
          notification("Asign", response.data.message, "danger");
        }
      })
      .catch((err) => {
        notification("Asign", err?.response?.data?.message, "danger");
      });
  };
};
export const getId = (id) => {
  return (dispatch) => {
    dispatch({
      type: "GET_OFFER_ID",
      id: id,
    });
  };
};

export const getDetail = (id) => {
  return async (dispatch, getState) => {
    //let id = getState().new_bookings.id;
    dispatch(loadingTrue());
    await axios.post(Resturl.offer_detail, { id: id }).then((response) => {
      dispatch({
        type: "GET_OFFER_DETAIL",
        offer_details: response.data.offers,
        // offer_price:response.data.offers
      });
    });
    dispatch(loadingFalse());
  };
};
